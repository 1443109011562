.box {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.box>* {
    flex: 1 400px;
    display: flex;
}

.box-child {
    flex: 1;
}

.summaryBox {
    text-align: left;
    padding: 0px 40px 30px;
    max-width: 900px;
}

summary-label {
    font-weight: bold!important;
    padding-right: 10px
}

.box-child:first-child {
    margin-right: 20px;
}

.form-container {
    padding: 0px 80px 30px 80px;
}

.form-label {
    font-weight: bold;
    align-self: flex-end;
    white-space: nowrap;
}

.wrap {
    white-space: normal;
}

.form-label-err {
    color: red
}

.disabled-form-label {
    color: #d9d9d9 !important;
}

.quote-form-action-area {
    background-color: #f2f2f2;
    width: 100%;
    height: 60px;
    padding: 15px;
    text-align: right;
}

.quote-form-header-area {
    background-color: #5d5d5d;
    height: 26px;
    color: white;
    padding: 30px 0 30px 20px;
    display: flex;
    align-items: center;
}

.form-save-btn {
    background-color: white;
    border: 1px solid #9f9f9f;
    border-radius: 6px;
    padding: 6px 16px;
    font-size: 8pt;
    margin-left: 10pt;
    cursor: pointer;
}

.disabled {
    background-color: #949494;
    color: #626262;
    border-color: #2f2f2f;
}

ul {
    padding-left: 60px
}

li {
    padding: 5px;
}

table {
    border-collapse: collapse;
    border:1px solid #000;
    width: 90%;
    font: 15px Arial, sans-serif;
    margin: 0 5%;
}
table td{
    border:1px solid #000000;
    padding:10px;
    width: 25%;
    text-align:left!important;
}
table td:first-child{
    border-left:0px solid #000000;
}
table td:last-child{
    text-align: right;
}
table th{
    border:1px solid #000;
    border-bottom: 1px solid;
    padding:10px;
    background-color: #ccc;
}

.blank {
    border-collapse: collapse;
    border:0px;
    width: 90%;
    font: 15px Arial, sans-serif;
    height: 50px;
    padding: 20px;
}

.blank td{
    border: 0px;
}

.warn {
    border-collapse: collapse;
    border:0px;
    width: 90%;
    font: 15px Arial, sans-serif;
    height: 50px;
    padding: 20px;
    text-align: center;
    color: red;
}

.warn td{
    border: 0px;
    text-align: center;
    color: red;
}

@media screen and (max-width: 992px) {
    .form-container {
        padding: 0px 40px 30px 40px;
    }

    .form-label {
        font-weight: bold;
        align-self: flex-end;
        white-space: nowrap;
        margin-top: 20px!important;
        margin-bottom: -10px!important;
    }

    table {
        border-collapse: collapse;
        border:1px solid #000;
        width: 90%;
        font: 12px Arial, sans-serif;
        margin: 0 5%;
        font-size: 16pt;
    }
}