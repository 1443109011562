.cost-tool-table{
  max-width: 100%;
  background-color: white;
  overflow-x: auto;
  border: 1px solid #C5C5C5;
  display: inline-flex;
}
.cost-tool-main-header{
  display: flex;
  width: 100%;
  height: 40px;
  background-color: #0039a6;
  border-right: 1px solid #a4a4a4;
}
.cost-tool-header{
  display: flex;
  width: 100%;
  height: 40px;
  background-color: #dadada;
  border-right: 1px solid #C5C5C5;
}

.cost-tool-header-summary{
  display: flex;
  width: 100%;
  height: 40px;
  background-color: #eaeaea;
  border-right: 1px solid #C5C5C5;
}


.cost-tool-header-cell{
  display: inline;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  padding: 7px;
  border-bottom: 1px solid #C5C5C5;
  min-width: 100px;
  max-width: 100px;
}

.cost-tool-row{
  display: flex;
  width: 100%;
  height: 40px;
  border-right: 1px solid #C5C5C5;
}

.cost-tool-group-details-row{
  display: flex;
  width: 100%;
  height: 40px;
  line-height: 26px;
}

.cost-tool-cell-arrow{
  display: inline;
  text-align: center;
  vertical-align: middle;
  min-width: 40px;
  max-width: 40px;
  padding: 7px 10px;
  border-bottom: 1px solid #C5C5C5;
}

.cost-tool-cell-cost-group-name{
  display: inline;
  text-align: left;
  vertical-align: middle;
  min-width: 280px;
  max-width: 280px;
  padding: 7px 10px 7px 0;
  border-bottom: 1px solid #C5C5C5;
}

.cost-tool-cell-table-btn-header{
  display: inline;
  text-align: right;
  vertical-align: middle;
  min-width: 280px;
  max-width: 280px;
  padding: 0px 3px 0 0;
  border-bottom: 1px solid #C5C5C5;
  color: white;
}

.cost-tool-cell-data{
  display: inline;
  text-align: center;
  vertical-align: middle;
  min-width: 100px;
  max-width: 100px;
  padding: 7px;
  border-bottom: 1px solid #f3f3f3;
}

.cost-tool-cell-alert{
  background-color: red !important;
  color: white !important;
}

.cost-group-detail-name {
  background-color: #3A61AC !important;
  color: white;
}

.cost-group-detail-cell-data {
  background-color: #5D7AB2 !important;
  color: white;
}

.cost-tool-cell-empty {
  background-color: #95a4c3 !important;
  color: #95a4c3;
}

.cost-group-warning {
  color: red !important;
}

.cost-group-warning-cell {
  background-color: red !important;
  color: white;
}

.cost-tool-cell-data-container-1 {
  background-color: #f9f9f9;
}
.cost-tool-cell-data-container-2 {
  background-color: #efefef;
}
.cost-tool-cell-data-container-3 {
  background-color: #efefef;
}
.cost-tool-cell-data-container-4 {
  background-color: #efefef;
}