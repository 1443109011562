.box {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.box>* {
    flex: 1 1 260px;
}

.form-label {
    font-weight: bold;
    align-self: flex-end;
}

.quote-form-action-area {
    background-color: #f2f2f2;
    width: 100%;
    height: 60px;
    padding: 15px;
    text-align: right;
}

.quote-form-header-area {
    background-color: #5d5d5d;
    height: 26px;
    color: white;
    padding: 30px 0 30px 20px;
    display: flex;
    align-items: center;
}

.form-save-btn {
    background-color: white;
    border: 1px solid #9f9f9f;
    border-radius: 6px;
    padding: 6px 16px;
    font-size: 8pt;
    margin-left: 10pt;
    cursor: pointer;
}

.disabled {
    background-color: #949494;
    color: #626262;
    border-color: #2f2f2f;
}

ul {
    padding-left: 60px
}

li {
    padding: 5px;
}

.MuiDataGrid-cellContent {
    padding-left: 20px
}

.MuiDataGrid-columnHeaderTitle {
    padding-left: 20px;
}

.MuiDataGrid-columnHeaderTitle {
    font-weight: bold !important;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
}

.MuiDataGrid-columnHeader:focus-within {
    outline: none !important;
}